export const ChromeExtensionOnboardingPath = '/onboarding-chrome-extension';

export const ChromeExtensionPath = '/chrome-extension';

export const ChromeExtensionPostDeletePath = '/offboarding-chrome-extension';

export const ChromeExtensionAuthPath = '/sign-in-ext';
export const ChromeExtensionTitle = 'Chrome Extension';

export const ChromeExtensionSidebarKey = 'chrome-extension-sidebar';
