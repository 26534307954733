export const CHROME_EXTENSION_ID = 'hmccfhejfgfjkmmmmhmlnhkoidinlaab';

export const CHROME_EXTENSION_URL = `https://chromewebstore.google.com/detail/justdone-ai-chrome-extens/${CHROME_EXTENSION_ID}`;

class Extension {
  sendAuthTokens(
    accessToken: string,
    refreshToken: string,
    isChrome?: boolean,
  ): void {
    try {
      if (isChrome) {
        chrome?.runtime?.sendMessage(CHROME_EXTENSION_ID, {
          key: 'auth',
          data: { access_token: accessToken, refresh_token: refreshToken },
          function(response) {
            if (!response.success) {
              // @ts-ignore
              console.error('error', response);
            }
          },
        });
      } else {
        const signInEvent = new CustomEvent('signIn', {
          detail: {
            access_token: accessToken,
            refresh_token: refreshToken,
          },
        });
        document.dispatchEvent(signInEvent);
        window.setTimeout(() => {
          document.dispatchEvent(signInEvent);
        }, 1000);
        window.setTimeout(() => {
          document.dispatchEvent(signInEvent);
        }, 2500);
      }

      // fallback for firefox
    } catch (e: any) {
      console.error(e);
    }
  }

  // TODO implement check installed extension method
}

export default new Extension();
